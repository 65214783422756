import ErrorMessage from "../utils/ErrorMessage";

const path = require("../config").path;
const axios = require("axios");
const moment = require("moment");

function getResult(productId, term, callback) {
    let sampleProduct = ["", "mega645", "power655", "max4d", "max3d", "max3d", "keno","235",'234',"6x36","thantai","123","max3d_pro"];
    let params = {};
    if(typeof term[0]=='string'){
        params.fromdate = term[0];
        params.todate = term[1];
    }
    else{
    params.fromid = term[0];
    params.toid = term[1];
    }

    // if(![1, 2, 6].includes(productId)) {
    //     return callback(null, null);
    //     return;
    // }
    axios.get(path.result[sampleProduct[productId]], {
        params: params
    })
        .then(res => {
            if(res.data.length === 0) {
                return callback(null, [])
            } else {
                let data = [];
                if([1, 2, 6, 9,10,11].includes(productId)) {
                    res.data.forEach(dt => {
                        data.push({
                            id: dt.id,
                            result: dt.result.split(","),
                           termDate:dt.termDate,
                            // date: productId === 6 ? moment(dt.date).format("DD/MM/YYYY HH:mm:ss") : dt.date.formatDateTime("-dmy", "/dmy")
                            date: dt.date.formatDateTime("-dmy", "/dmy")
                        })
                    });
                } else {
                    res.data.forEach(dt => {
                        data.push({
                            id: dt.id,
                            termDate:dt.termDate,
                            result: dt.result,
                            date: dt.date.formatDateTime("-dmy", "/dmy")
                        })
                    });
                }

                return callback(null, data);
            }
        })
        .catch(error => {
            ErrorMessage(error);
            if (callback)
                return callback(error);
        })
}

function getLastPeriod(productId, callback) {
    let productType = ["", "mega645", "power655", "max4d", "max3d", "max3d", "keno","235",'234',"6x36","thantai","123","max3d_pro"];
    axios.get(path.result[productType[productId]], {timeout: 2000})
        .then(res => {
            if (callback){
                if([1, 2].includes(productId))
                    return callback(null, {
                        jackpot: res.data.statistical.find(dt => dt.giaithuong===(productId===1 ? "Jackpot" : "Jackpot 1")).giatrigiai,
                        date: moment(res.data.date, "DD-MM-YYYY").format("DD/MM/YYYY"),
                        id: res.data.id
                    });
                else{
                    return callback(null, {
                        date: moment(res.data.date, "DD-MM-YYYY").format("DD/MM/YYYY"),
                        id: res.data.id,
                        term:res.data.termDate
                    });
                }
            }

        })
        .catch(error => {
            ErrorMessage(error);
            if (callback)
                return callback(error);
        })
}

function getNearestTerm(productId, callback) {
    let productType = ["", "mega645", "power655", "max4d", "max3d", "max3d", "keno","235",'234',"6x36","thantai","123","max3d_pro"];
    axios.get(path.product[productType[productId]], {timeout: 2000})
        .then(res => {
            return callback(null, res.data.term[0])
        })
        .catch(error => {
            ErrorMessage(error);
            if (callback)
                return callback(error);
        })
}

function statistical(productId, date, callback) {
    let productType = ["", "mega645", "power655", "max4d", "max3d", "max3d", "keno"];
    axios.get(path.analytic[productType[productId]], {params: {fromdate: date[0], todate: date[1], sorttype: 1}})
        .then(res => {
            let data = {
                bo_so: [],
                bo_so_tang_dan: [],
                bo_so_giam_dan: [],
                xuat_hien_it_nhat: [],
                xuat_hien_nhieu_nhat: [],
                total: 0,
            };
            let tmp = [];

            data.bo_so = res.data;

            data.bo_so_tang_dan = res.data.sortBy("count");
            tmp = JSON.parse(JSON.stringify(data.bo_so_tang_dan));
            data.bo_so_giam_dan= tmp.reverse();

            data.bo_so.forEach(dt => {
                data.total += dt.count;
            });
            for(let i=0; i<10; i++) {
                data.xuat_hien_nhieu_nhat.push(data.bo_so_giam_dan[i]?.number)
            }
            for(let i=0; i<10; i++) {
                data.xuat_hien_it_nhat.push(data.bo_so_tang_dan[i]?.number)
            }
            // tmp = JSON.parse(JSON.stringify(data.bo_so_tang_dan));
            // data.xuat_hien_it_nhat = tmp.slice(-10).sortBy("number");
            // tmp = JSON.parse(JSON.stringify(data.bo_so_giam_dan));
            // data.xuat_hien_nhieu_nhat = tmp.slice(-10).sortBy("number");
            //
            // tmp = [];
            // data.xuat_hien_nhieu_nhat.forEach(dt => {
            //     tmp.push(dt.number);
            // });
            // data.xuat_hien_nhieu_nhat = tmp;
            //
            // tmp = [];
            // data.xuat_hien_it_nhat.forEach(dt => {
            //     tmp.push(dt.number);
            // });
            // data.xuat_hien_it_nhat = tmp;

            return callback(null, data);
        })
        .catch(error => {
            ErrorMessage(error);
            return callback(error);
        })
}

function statisticalKenoByTerm(term, callback) {
    axios.get(path.analytic.keno, {
        params: {periodid: term, sorttype: 1}
    })
        .then(res => {
            let data = {
                bo_so: [],
                bo_so_tang_dan: [],
                bo_so_giam_dan: [],
                xuat_hien_it_nhat: [],
                xuat_hien_nhieu_nhat: [],
                total: 0,
            };
            let tmp = [];

            data.bo_so = res.data;

            data.bo_so_tang_dan = res.data.sortBy("count");
            tmp = JSON.parse(JSON.stringify(data.bo_so_tang_dan));
            data.bo_so_giam_dan= tmp.reverse();

            data.bo_so.forEach(dt => {
                data.total += dt.count;
            });

            tmp = JSON.parse(JSON.stringify(data.bo_so_tang_dan));
            data.xuat_hien_it_nhat = tmp.slice(-10).sortBy("number");
            tmp = JSON.parse(JSON.stringify(data.bo_so_giam_dan));
            data.xuat_hien_nhieu_nhat = tmp.slice(-10).sortBy("number");

            tmp = [];
            data.xuat_hien_nhieu_nhat.forEach(dt => {
                tmp.push(dt.number);
            });
            data.xuat_hien_nhieu_nhat = tmp;

            tmp = [];
            data.xuat_hien_it_nhat.forEach(dt => {
                tmp.push(dt.number);
            });
            data.xuat_hien_it_nhat = tmp;

            return callback(null, data);
        })
        .catch(error => {
            ErrorMessage(error);
            return callback(error);
        })
}

function getAllResult(callback) {
    axios.get(path.result.all)
        .then(res => {
            let data = res.data;
            return callback(null, {
                "mega645":(parseInt(data.MEGA.statistical[0].giatrigiai.replace('.','').replace('.','').replace('.',''))/1000000000).toFixed(1)  + " TỶ VNĐ",
                "power655":(parseInt(data.POWER.statistical[0].giatrigiai.replace('.','').replace('.','').replace('.',''))/1000000000).toFixed(1)  + " TỶ VNĐ",
                "max3d": "x100 lần",
                "max3d_plus": "x100.000 lần",               
                "max4d": "x1500 lần",
                "keno": "2 TỶ VNĐ",
                "max3d_pro":"x200.000 lần"
            })
        })
        .catch(error => {
            ErrorMessage(error);
            if (callback)
                return callback(error);
        })
}

async function getResultKtmn(date) {
    try {
        const response = await axios.get(`${path.result.ktmn}&fromdate=${date}&todate=${date}`);
        // Xử lý dữ liệu khi nhận được phản hồi thành công
        
        return [null,response.data]
    } catch (error) {
        // Xử lý lỗi khi gặp lỗi trong quá trình gọi API
        console.error(error);
        return [error]
    }
}

export default {
    getResult: getResult,
    getLastPeriod: getLastPeriod,
    getNearestTerm: getNearestTerm,
    statistical,
    statisticalKenoByTerm,
    getAllResult,
    getResultKtmn
}
