import React, { Component } from 'react';
import moment from "moment";

import {
    Grid, HardDrive, Book
} from "react-feather"

import colors from "../../utils/colors";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Link } from "react-router-dom";

import utils from "../../utils";

import api from "../../api/Result";
import AddToHomescreen from 'react-add-to-homescreen';
import { Helmet } from "react-helmet";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
var Carousel = require('react-responsive-carousel').Carousel;

const menuCardData = [
    {
        productId: 7,
        lottery: "235",
        color: colors.redColor,
    },
    {
        productId: 6,
        lottery: "keno",
        color: colors.redColor,
    },
    {
        productId: 6,
        lottery: "kenobao",
        color: colors.redColor,
    },
    {
        productId: 6,
        lottery: "keno-chanle",
        color: colors.redColor,
    },
    {
        productId: 1,
        lottery: "mega645",
        name: 'Hàng ngày',
        color: colors.facebookColor,
        title: 'Mega 645',
        prize: 'x450 lần',
        loto: 'T4,T6,CN'
    },
    {
        productId: 2,
        lottery: "power655",
        name: 'Hàng ngày',
        color: colors.facebookColor,
        title: 'Power 655',
        prize: 'x20.000 lần',
        loto: 'T3,T5,T7'
    },
    {
        productId: 12,
        lottery: 'max3d_pro',
        name: 'Hàng ngày',
        color: colors.facebookColor,
        title: 'Max3D Pro',
        prize: 'x1000 lần',
        loto: 'T3,T5,T7'
    },
    {
        productId: 4,
        lottery: 'max3d',
        name: 'Hàng ngày',
        color: colors.facebookColor,
        title: 'Max3D',
        prize: 'x15 lần',
        loto: 'T2,T4,T6'
    },
    {
        productId: 5,
        lottery: 'max3d_plus',
        name: 'Hàng ngày',
        color: colors.facebookColor,
        title: 'Max3D Plus',
        prize: 'x60 lần',
        loto: 'T2,T4,T6'
    },



];

const logo = {
    'mega645': require('../../assets/images/logo/mega645.png'),
    'power655': require('../../assets/images/logo/power655.png'),
    'max4d': require('../../assets/images/logo/max4d.png'),
    'max3d': require('../../assets/images/logo/max3d.png'),
    'max3d_plus': require('../../assets/images/logo/max3d_plus.png'),
    'max3d_pro': require('../../assets/images/logo/logomax3dpro.png'),
    'keno': require('../../assets/images/logo/keno.png'),
    '235': require("../../assets/images/logo/dien-toan.png"),
    'kenobao': require('../../assets/images/logo/kenobao.png'),
    'keno-chanle': require('../../assets/images/logo/keno.png'),
    '235': require("../../assets/images/logo/dien-toan.png")
};
class MenuCard extends React.Component {
    constructor(props) {
        super(props);
        this.itv = null;
        this.countDownDate = new Date(utils.generateCountDownDate(6)).getTime();
        this.state = {
            date: moment().format('DD/MM/YYYY HH:MM:SS'),
            time: '00:00:00:00',
            nextDate: '01/01/2020',
            nextDateTime: '01/01/2020 18:00:00',
        };
    }

    timeGenerate() {
        return this.state.time;
    }

    handleRefresh() {


        api.getNearestTerm(this.props.productId, (err, result) => {
            if (err) {

            } else {
                const that = this;
                if (this.itv) {
                    clearInterval(this.itv);
                }
                if (this.props.productId !== 6) {
                    let nextDate = result.date;
                    nextDate = moment(nextDate, 'DD-MM-YYYY');
                    nextDate.set('hour', 18);
                    nextDate.set('minute', 0);
                    nextDate.set('second', 0);

                    this.setState({
                        nextDateTime: nextDate.format('DD/MM/YYYY HH:MM:SS'),
                        nextDate: nextDate.format('DD/MM/YYYY'),
                    });
                    that.itv = setInterval(function () {
                        let countDown = (nextDate - moment()) / 1000;
                        let day = Math.floor(countDown / 60 / 60 / 24);
                        let hour = 0;
                        let minute = 0;
                        let second = 0;
                        hour = Math.floor((countDown - day * 24 * 60 * 60) / 60 / 60);
                        minute = Math.floor((countDown - day * 24 * 60 * 60 - hour * 60 * 60) / 60);
                        second = Math.floor((countDown - day * 24 * 60 * 60 - hour * 60 * 60 - minute * 60));
                        if ((day === 0 && hour === 0 && minute === 0 && second === 0) || day < 0) {
                            clearInterval(that.itv);
                            that.handleRefresh();
                            return;
                        }
                        let str = `${utils.formatValue(day)}:${utils.formatValue(hour)}:${utils.formatValue(minute)}:${utils.formatValue(second)}`;
                        that.setState({ time: str });
                    }, 1000);
                } else {

                    var end = moment(result.date, 'DD-MM-YYYY HH:mm:ss').add(-1, 'minutes')

                    that.itv = setInterval(function () {
                        const timeLeft = moment(end.diff(moment())).utc();
                        const formatted = timeLeft.format('HH:mm:ss');
                        if (end.diff(moment()) <= 0) {
                            that.handleRefresh();
                            return;
                        }
                        that.setState({ time: formatted });
                    }, 1000);
                }
            }
        });

    }

    componentDidMount() {
        this.handleRefresh();
    }

    componentWillUnmount() {
        clearInterval(this.itv);
    }
    isHot() {
        if (this.props.productId === 7)
            return true;
        return false;
    }
    isToday() {
        if (this.props.productId === 6) {
            return false;
        }

        const dt = moment(this.state.nextDate, 'DD/MM/YYYY');
        const today = moment();
        if (dt.get('days') === today.get('days') && dt.get('months') === today.get('months')) {
            return true;
        }
        return false;
    }
    renderTopTitle = () => {
        if (this.props.productId == 6 || this.props.productId == 7) {
            return <div className='time-btn'>
                <span className='fnt-size-13-or'>{this.props.productId == 7 ? 'Ngày quay' : '5 phút/kỳ'}</span>
                <div className='box-or-time'>Cả tuần</div>
            </div>
        }
        else {
            return (<div className='time-btn'>
                <span className='fnt-size-13-or'>Ngày quay</span>
                <div className='box-or-time' style={{ fontSize: 12 }}>{this.isToday() ? 'Hôm nay xổ' : this.props.loto}</div>
            </div>)
        }

    }
    render() {
        return (
            <Link to={this.props.productId === 7 ? "/" : `/${this.props.lottery}`} className='box-w-btn link-unset' >
                <div className='box-soxo-btn'>

                    {this.renderTopTitle()}

                    <div className='center-btn'>
                        <img src={logo[this.props.lottery]} className='img-logo' alt="icon"

                            style={this.props.productId == 7 ? { marginRight: 5, paddingRight: 5, paddingLeft: 5, width: 60 } : { marginRight: 5, paddingRight: 5, paddingLeft: 5 }} />
                        <div className='fnt-size-13' style={{ flex: 1 }}>
                            <div>
                                {/* {this.props.title} */}
                                <div className='fnt-size-18'  >
                                    {this.props.content}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='value-btn'>
                        <span className='pd-10'>
                            Còn lại:
                            <span style={{ fontWeight: 'bold' }}> {this.state.time}</span>
                        </span>

                    </div>
                </div>

            </Link>
        )

    }
}


class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            content: {
                "mega645": "--.---.---.---- ₫",
                "power655": "---.---.---.--- ₫",
                "max3d": "x100 lần",
                "max3d_plus": "x100.000 lần",
                "max4d": "x1500 lần",
                "keno": "Keno - 2 tỷ vn₫",
                "235": "Xổ số Miền Bắc",
                "kenobao": "Keno Bao",
                "keno-chanle": "CHẴN LẺ LỚN NHỎ"
            },
            seo: {}
        }
    }


    async componentDidMount() {
        api.getAllResult((err, result) => {
            if (result) {
                let content = JSON.parse(JSON.stringify(this.state.content));
                content = {
                    ...content,
                    ...result
                };
                this.setState({ content })
            }
        })
        var seo = await utils.fetchSeo('home.json');
        this.setState({ seo: seo });
    }
    handleAddToHomescreenClick = () => {
        alert(`
        1. Open Share menu
        2. Tap on "Add to Home Screen" button`);
    }
    navigateDownload = () => {

        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        window.location.href = '/app/';

        return;
    }
    render() {
        return (
            <div className="home-page">

                <div style={{ height: "100%", overflowY: "auto" }}>
                    {/* <Carousel
            showArrows={true}
            showIndicators={true}
            showThumbs={false}
            showStatus={false}
            autoPlay={true}
            infiniteLoop={true}
            swipeable={true}
        >
            {
                Array(1).fill(1).map((d, i) => (
                    <div key={i}>
                        <img  src='https://xoso-app.vn/media/mobile/images/banner1.jpg'  alt="VIETLOTT-BANNER"/>
                    </div>
                ))
            }
        </Carousel> */}

                    <div style={{ marginTop: 10 }}>
                        <div className='row-btn'>
                            <a href={"/recharge"} className='row-btn-money'><span className='row-btn-text'>NẠP TIỀN</span></a>
                            <a href={'/cart'} className='row-btn-money' ><span className='row-btn-text'>GIỎ HÀNG</span></a>
                            <a href={'/withdrawal'} className='row-btn-money'><span className='row-btn-text'>RÚT TIỀN</span></a>
                        </div>
                    </div>

                    <div className='container-soxo-btn'>
                        {
                            menuCardData.map(e => (
                                <MenuCard
                                    title={e.title}
                                    lottery={e.lottery}
                                    productId={e.productId}
                                    loto={e.loto}
                                    title_2={e.title_2}
                                    title_3={e.title_3}
                                    title_3_1={e.title_3_1}
                                    content={this.state.content[e.lottery]}
                                />
                            ))
                        }
                    </div>

                </div>
                {/* <div style={{flexDirection:'row',display:'flex',flexGrow:1,width:370}}>
                     <a href='/' style={{backgroundColor:'#fff',flex:1, borderRadius:10, overflow:'hidden',margin:10,justifyContent:'center',display:'flex'}}>
                     <img  style={{width:'70%'}} src={require('../../assets/images/logo/vietlott.jpg')}/>
                     </a>
                     <a href='/xsmb' style={{backgroundColor:'#fff',flex:1, borderRadius:10, overflow:'hidden',margin:10,justifyContent:'center',display:'flex'}}>
                     <img  style={{width:'70%'}} src={require('../../assets/images/logo/dientoan.jpg')}/>
                     </a>
                 </div>    */}
                <div className="home-page__footer py-2 d-flex bgNav">
                    <div className="w-50 text-center">
                        <Link to={"/"} className="link-unset">
                            <div>
                                <Grid color={window.location.pathname === "/" ? "#fff" : "#eacad3"} />
                            </div>
                            <div>
                                <span
                                    style={{
                                        fontSize: "0.875rem",
                                        color: window.location.pathname === "/" ? "#fff" : "#eacad3"
                                    }}
                                >Đặt vé</span>
                            </div>
                        </Link>
                    </div>
                    <div className="w-50 text-center">
                        <a href="/huong-dan" className="link-unset" target={'_blank'}>
                            <div>
                                <Book color={window.location.pathname === "/huong-dan" ? "#fff" : "#eacad3"} />
                            </div>
                            <div>
                                <span
                                    style={{
                                        fontSize: "0.875rem",
                                        color: window.location.pathname === "/huong-dan" ? "#fff" : "#eacad3"
                                    }}
                                >Hướng dẫn</span>
                            </div>
                        </a>
                    </div>
                    <div className="w-50 text-center">
                        <Link to={"/truc-tiep-keno"} className="link-unset">
                            <div>
                                <div class="livenow">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                            <div>
                                <span
                                    style={{
                                        fontSize: "0.875rem",
                                        color: window.location.pathname === "/truc-tiep-keno" ? "#fff" : "#eacad3"
                                    }}
                                >Trực tiếp keno</span>
                            </div>
                        </Link>
                    </div>
                    <div className="w-50 text-center">
                        <Link to={"/result"} className="link-unset">
                            <div>
                                <HardDrive color={window.location.pathname === "/result" ? "#fff" : "#eacad3"} />
                            </div>
                            <div>
                                <span
                                    style={{
                                        fontSize: "0.875rem",
                                        color: window.location.pathname === "/result" ? "#fff" : "#eacad3"
                                    }}
                                >Kết quả</span>
                            </div>
                        </Link>
                    </div>



                </div>




                {/* <AddToHomescreen onAddToHomescreenClick={this.handleAddToHomescreenClick} /> */}
                <div className='btn-download' onClick={() => this.navigateDownload()}>

                    <FontAwesomeIcon
                        icon={faDownload}
                        style={{ marginRight: 2 }}

                    />
                    <p style={{ fontSize: 10, margin: 0 }} >Tải app</p>
                </div>
            </div>
        );
    }
}

export default Home;
